import './App.css';
import SearchBox from './component/searchbox/searchbox.component';
function App() {
  return (

      <SearchBox></SearchBox>

  );
}

export default App;
